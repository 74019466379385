import { ApiService, useApiService } from "./useApiService";
import * as _ from "lodash-es";
import { Brand } from "models/Brand";
import { setBrandColor } from "helpers/brand-colors";
import { urls } from "const/urls";
import { environment } from "environments";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useBrandService(): BrandService {
  const api = useApiService();
  return useService(ud, BrandService, { api });
}

export class BrandService extends Service {
  public static serviceName = "BrandService";

  public brand: Brand = _.cloneDeep(environment.defaultBrand);

  private isLoading = true;
  public get loading(): boolean {
    return this.isLoading;
  }

  private readonly api: ApiService;

  constructor({ api }: { api: ApiService }) {
    super({ api });
    this.api = api;
  }

  public async init(): Promise<void> {
    interface BrandDTO {
      brand_url: string;
      brand_title: string;
      brand_call_recording_title: string;
      brand_call_forwarding_title: string;
      brand_color: string;
      brand_text_color: string;
      brand_back_color: string;
      brand_sms_wss: string;
      brand_sip_transport: string;
      brand_sip_server: string;
      brand_sip_port: number;
      brand_sip_useragent: string;
    }

    let brandDto: Partial<BrandDTO> = {};
    try {
      brandDto = await this.api.get<Partial<BrandDTO>>(urls.brand, {
        allowAnonymous: true,
        ignoreErrors: [403, 0],
      });
    } catch (err) {
      console.error(err);
    }
    brandDto = brandDto || {};

    // brandDto = { brand_title: 'Apple', brand_color: '#800090', brand_back_color: '#323', brand_text_color: '#ebe', brand_url: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Apple-logo.png' };

    const brand: Brand = _.cloneDeep(environment.defaultBrand);
    brand.logoUrl = brandDto.brand_url || brand.logoUrl;
    brand.title =
      brandDto.brand_title || (brandDto.brand_url ? "" : brand.title);
    brand.callRecordingTitle =
      brandDto.brand_call_recording_title ||
      (brandDto.brand_title ? `${brandDto.brand_title} Call Recordings` : "") ||
      (brandDto.brand_url ? "Call Recordings" : brand.callRecordingTitle);
    brand.callForwardingTitle =
      brandDto.brand_call_forwarding_title ||
      (brandDto.brand_title ? `${brandDto.brand_title} Call Forwarding` : "") ||
      (brandDto.brand_url ? "Call Forwarding" : brand.callForwardingTitle);
    brand.color = brandDto.brand_color || brand.color;
    brand.textColor = brandDto.brand_text_color || brand.textColor;
    brand.background = brandDto.brand_back_color || brand.background;
    brand.color = brandDto.brand_color || brand.color;
    if (
      brandDto.brand_sip_transport ||
      brandDto.brand_sip_server ||
      brandDto.brand_sip_port ||
      brandDto.brand_sip_useragent
    ) {
      brand.sip = {
        transport: brandDto.brand_sip_transport || brand.sip.transport,
        server: brandDto.brand_sip_server || brand.sip.server,
        port: brandDto.brand_sip_port || brand.sip.port,
        userAgent: brandDto.brand_sip_useragent || brand.sip.userAgent,
      };
    }
    brand.smsWebSocket = brandDto.brand_sms_wss || brand.smsWebSocket;
    this.brand = brand;

    const { color, background, textColor } = this.brand;
    setBrandColor({ color, background, textColor });
    this.isLoading = false;
  }
}
