import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatPhoneNumber } from "helpers/phone";
import { useContactService } from "hooks/services/useContactService";
import { useNavigationService } from "hooks/services/useNavigationService";
import { Page, messageTypesByPage } from "models/navigation";
import { Loader } from "components/Loader/Loader";
import { useSmsService } from "hooks/services/useSmsService";
import { useVoicemailService } from "hooks/services/useVoicemailService";
import { useCallService } from "hooks/services/useCallService";
import { Button } from "primereact/button";
import { GoPlus } from "react-icons/go";
import { Contact } from "models/Contact";
import { useFaxService } from "hooks/services/useFaxService";
import { ContactCard } from "./ContactCard";
import * as _ from "lodash-es";
import { Search } from "components/Search/Search";
import { useContactSearch } from "hooks/useSearch";
import { useMmsService } from "hooks/services/useMmsService";
import { useCallRecordingService } from "hooks/services/useCallRecordingService";
import { useChatService } from "hooks/services/useChatService";
import "./Contacts.scss";
import { Id } from "models/common";

export const Contacts = () => {
  const navigation = useNavigationService();
  const contactService = useContactService();
  const callService = useCallService();
  const callRecordingService = useCallRecordingService();
  const smsService = useSmsService();
  const mmsService = useMmsService();
  const voiceMailService = useVoicemailService();
  const faxService = useFaxService();
  const chatService = useChatService();

  const [searchPattern, setSearchPattern] = useState("");

  const selectContact = useCallback(
    (contact: Contact | undefined) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      contactService.current = contact;
      contactService.update();
    },
    [contactService]
  );

  const page: Page = navigation.page!;

  const contacts = useMemo(
    () => contactService.getContactsForPage(page),
    [contactService, page]
  );
  const hasContacts = contacts.length > 0;

  const [unreadCountByContact, setUnreadCountByContact] = useState<
    Record<Id, number>
  >({});

  useEffect(
    () => {
      const availableMessageTypes = messageTypesByPage(page);
      (async () => {
        setUnreadCountByContact(
          _.fromPairs(
            await Promise.all(
              contacts.map((contact) =>
                chatService
                  .getUnreadCount({
                    contactId: contact.id,
                    messageTypes: availableMessageTypes,
                  })
                  .then((count) => [contact.id, count])
              )
            )
          )
        );
      })();
    },
    [page, contacts, chatService, chatService.updates] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const hasNewButton =
    navigation.page === Page.callHistory ||
    ([Page.chats, Page.sms, Page.fax].includes(page) &&
      Boolean(contactService.current));

  // filter by search pattern
  let filteredContacts = useContactSearch({
    contacts,
    searchPattern,
  });

  // sort contacts
  filteredContacts = _.sortBy(filteredContacts, [
    (c) => !c.pinned,
    (c) => -(c.lastMessage?.at || 0),
    (c) => c.numbers.every((number) => c.name !== formatPhoneNumber(number)),
    (c) => c.name,
  ]);

  const newContactClick = useCallback(() => {
    if (navigation.page === Page.callHistory) {
      navigation.page = Page.dialer;
      return;
    }
    contactService.current = undefined;
    contactService.update();
    return;
  }, [navigation, contactService]);

  useEffect(() => {
    const element = document.querySelector(".contacts-component .selected");
    if (element) {
      element.scrollIntoView({ block: "nearest" });
    }
  }, [contactService.current?.id]);

  const loading =
    ([Page.chats, Page.callHistory].includes(page) && !callService.isReady) ||
    ([Page.chats, Page.callRecordings].includes(page) &&
      !callRecordingService.isReady) ||
    ([Page.chats, Page.sms].includes(page) && !smsService.isReady) ||
    ([Page.chats, Page.sms, Page.gallery].includes(page) &&
      !mmsService.isReady) ||
    ([Page.chats, Page.voicemails].includes(page) &&
      !voiceMailService.isReady) ||
    ([Page.chats, Page.fax].includes(page) && !faxService.isReady);

  const newMessageMessage = Page.callHistory
    ? "Make a new call"
    : "Message to a new contact";

  const noContactsMessage = [
    "No ",
    page === Page.callHistory
      ? "calls"
      : page === Page.callRecordings
      ? "call recordings"
      : page === Page.sms
      ? "SMS/MMS"
      : page === Page.gallery
      ? "MMS"
      : page === Page.voicemails
      ? "voicemails"
      : page === Page.fax
      ? "fax"
      : "contacts",
    "found",
  ].join(" ");

  return (
    <div className="contacts-component">
      {hasContacts && (
        <Search
          searchPattern={searchPattern}
          setSearchPattern={setSearchPattern}
        />
      )}
      {loading && <Loader />}
      <ul className="contacts" onClick={selectContact(undefined)}>
        {navigation.page === Page.gallery && (
          <li
            className={`contact-card-component ${
              contactService.current ? "" : "selected"
            }`}
          >
            <div className="name">All Contact Images</div>
          </li>
        )}
        {filteredContacts.map((contact) => {
          const lastMessage =
            page === Page.callHistory
              ? callService.getMessagesByContactId(contact.id)[0]
              : page === Page.callRecordings
              ? callRecordingService.getMessagesByContactId(contact.id)[0]
              : page === Page.voicemails
              ? voiceMailService.getMessagesByContactId(contact.id)[0]
              : page === Page.sms
              ? contact.lastSmsMessage && contact.lastMmsMessage
                ? contact.lastSmsMessage.at > contact.lastMmsMessage.at
                  ? contact.lastSmsMessage
                  : contact.lastMmsMessage
                : contact.lastSmsMessage ||
                  contact.lastMmsMessage ||
                  contact.lastMessage
              : page === Page.gallery
              ? contact.lastMmsMessage || contact.lastMessage
              : page === Page.fax
              ? faxService.getMessagesByContactId(contact.id)[0]
              : contact.lastMessage;
          return (
            <ContactCard
              key={contact.id}
              contact={contact}
              lastMessage={lastMessage}
              unreadCount={unreadCountByContact[contact.id]}
            />
          );
        })}
        {filteredContacts.length === 0 ? (
          <div className="no-contacts">{noContactsMessage}</div>
        ) : null}
      </ul>
      {hasNewButton && (
        <Button
          className="p-button-rounded new-contact-btn"
          onClick={newContactClick}
          title={newMessageMessage}
        >
          <GoPlus />
        </Button>
      )}
    </div>
  );
};
