import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatPhoneNumber } from "helpers/phone";
import { useContactService } from "hooks/services/useContactService";
import { Loader } from "components/Loader/Loader";
import { useSmsService } from "hooks/services/useSmsService";
import { GoPlus } from "react-icons/go";
import { SmsList } from "models/SmsList";
import {
  Contact,
  getContactDisplayName,
  getContactDisplayNameWithNumbers,
} from "models/Contact";
import * as _ from "lodash-es";
import { FormFields } from "../FormFields/FormFields";
import { useSmsListService } from "hooks/services/useSmsListService";
import { Search } from "components/Search/Search";
import { useContactSearch } from "hooks/useSearch";
import { xor } from "helpers/math";
import "./SmsListRecepients.scss";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import Select from "react-select";

export const SmsListRecepients = ({ list }: { list: SmsList }) => {
  const contactService = useContactService();
  const smsService = useSmsService();
  const smsListService = useSmsListService();

  const [searchPattern, setSearchPattern] = useState("");
  const [removingContact, setRemovingContact] = useState<Contact | undefined>(
    undefined
  );
  const [newRecepeientsVisible, setNewRecepeientsVisible] = useState(false);
  const [newPhoneNumberInput, setNewPhoneNumberInput] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const newPhoneNumberRef = React.createRef<HTMLDivElement>();

  // filter by search pattern
  let filteredContacts = useContactSearch({
    contacts: contactService.contacts.filter((c) =>
      list.contactData.some((cd) => c.numbers.includes(cd.number))
    ),
    searchPattern,
  });

  filteredContacts = _.sortBy(filteredContacts, [
    (c) =>
      c.numbers.some((number) => c.name && c.name !== formatPhoneNumber(number))
        ? 0
        : 1,
    (c) => c.name,
  ]);

  const newRecepients: Contact[] = contactService.contacts.filter((c) =>
    list.contactData.every((cd) => !c.numbers.includes(cd.number))
  );
  const newRecepientOptions = [
    ...newRecepients.map((c: Contact) => ({
      label: getContactDisplayNameWithNumbers(c),
      value: c.currentNumber || c.numbers[0],
    })),
    ...(newPhoneNumberInput &&
    newRecepients.every((r) => !r.numbers.includes(newPhoneNumberInput))
      ? [
          {
            label: formatPhoneNumber(newPhoneNumberInput),
            value: newPhoneNumberInput,
          },
        ]
      : []),
  ];

  const addNewRecepient = () => {
    console.log(newPhoneNumber, newPhoneNumberInput);
    smsListService.addContactToList(newPhoneNumber, list);
    setNewRecepeientsVisible(false);
  };

  const onRemoveButtonClick = (contact: Contact) => () => {
    setRemovingContact(contact);
  };

  const removeContactFromTheList = () => {
    if (!removingContact) {
      return;
    }
    smsListService.removeContactFromList(removingContact, list);
    setRemovingContact(undefined);
  };

  const showNewRecepeientPanel = () => {
    setNewRecepeientsVisible(true);
    setTimeout(() => {
      newPhoneNumberRef.current?.scrollIntoView();
      newPhoneNumberRef.current?.querySelector("input")?.focus();
    }, 200);
  };

  return (
    <div className="sms-list-recepients-component auto-flex">
      <Search
        searchPattern={searchPattern}
        setSearchPattern={setSearchPattern}
      />
      {!smsService.isReady && <Loader />}
      {!newRecepeientsVisible && (
        <Button
          className="p-button-rounded new-contact-btn"
          onClick={showNewRecepeientPanel}
          title="Add a contact to the SMS List"
        >
          <GoPlus />
        </Button>
      )}
      {filteredContacts.length > 0 ? (
        <Accordion multiple className="recepients">
          {filteredContacts.map((contact) => (
            <AccordionTab
              key={contact.id}
              header={getContactDisplayName(contact)}
            >
              <FormFields contact={contact} />
              <Button
                className="remove-contact-btn p-button-rounded p-button-danger"
                onClick={onRemoveButtonClick(contact)}
              >
                Remove the contact from the SMS list
              </Button>
            </AccordionTab>
          ))}
        </Accordion>
      ) : (
        <div className="no-contacts">No recepients in the SMS List</div>
      )}
      <div className="new-recepient" ref={newPhoneNumberRef}>
        {newRecepeientsVisible && (
          <>
            <Select
              value={newRecepientOptions.find(
                (o) => o.value === newPhoneNumber
              )}
              options={newRecepientOptions}
              onChange={(option: any) => setNewPhoneNumber(option.value)}
              placeholder="Phone number to add"
              onInputChange={(value: string) => setNewPhoneNumberInput(value)}
            />
            <button className="p-button" onClick={addNewRecepient}>
              Add
            </button>
          </>
        )}
      </div>
      <ConfirmDialog
        visible={Boolean(removingContact)}
        onHide={() => setRemovingContact(undefined)}
        message={`Are you sure you want to remove ${getContactDisplayNameWithNumbers(
          removingContact
        )} from the SMS list "${list.name}"?`}
        header="Remove Confirmation"
        accept={removeContactFromTheList}
        reject={() => setRemovingContact(undefined)}
      />
    </div>
  );
};
