import React from "react";
import {
  BsCardChecklist,
  BsChatText,
  BsMap,
  BsNewspaper,
  BsTelephone,
  BsVoicemail,
} from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
import { IoRecordingOutline } from "react-icons/io5";
import { SlCallOut } from "react-icons/sl";
import { routes } from "const/routes";
import { MessageType } from "./Message";
import { Feature } from "./MyNumber";

export enum AppMode {
  home = "home",
  chats = "chats",
  calls = "calls",
  callRecodings = "callRecordings",
  callForwarding = "callForwarding",
  sms = "sms",
  voicemails = "voicemails",
  fax = "fax",
  map = "map",
}

export const modes: AppMode[] = [
  AppMode.home,
  AppMode.chats,
  AppMode.calls,
  AppMode.callRecodings,
  AppMode.callForwarding,
  AppMode.sms,
  AppMode.voicemails,
  AppMode.fax,
  AppMode.map,
];

export const modeInfo: Record<AppMode, { icon: React.FC; name: string }> = {
  [AppMode.home]: { icon: () => <AiOutlineHome />, name: "Home" },
  [AppMode.chats]: { icon: () => <BsCardChecklist />, name: "All Chats" },
  [AppMode.calls]: { icon: () => <BsTelephone />, name: "Calls" },
  [AppMode.callRecodings]: {
    icon: () => <IoRecordingOutline />,
    name: "Call Recordings",
  },
  [AppMode.callForwarding]: {
    icon: () => <SlCallOut />,
    name: "Call Forwarding",
  },
  [AppMode.sms]: { icon: () => <BsChatText />, name: "SMS & MMS" },
  [AppMode.voicemails]: { icon: () => <BsVoicemail />, name: "Voicemails" },
  [AppMode.fax]: { icon: () => <BsNewspaper />, name: "Fax" },
  [AppMode.map]: { icon: () => <BsMap />, name: "Map" },
};

export enum Page {
  home = "home",
  activity = "activity",
  config = "config",
  tools = "tools",
  chats = "chats",
  callHistory = "callHistory",
  dialer = "dialer",
  activeCall = "activeCall",
  callForwarding = "callForwarding",
  callRecordings = "callRecordings",
  callProcedures = "callProcedures",
  callRecordingTable = "callRecordingTable",
  callRecordingSettings = "callRecordingSettings",
  sms = "sms",
  smsTemplates = "smsTemplates",
  smsList = "smsList",
  autoResponder = "autoResponder",
  gallery = "gallery",
  voicemails = "voicemails",
  greetings = "greetings",
  fax = "fax",
  map = "map",
}

type RouteUrl = (typeof routes)[keyof typeof routes];

export const pageInfo: Record<Page, { name: string; route: RouteUrl }> = {
  [Page.home]: { name: "Home", route: routes.home },
  [Page.activity]: { name: "Activity", route: routes.activity },
  [Page.config]: { name: "Configuration", route: routes.config },
  [Page.tools]: { name: "Tools", route: routes.tools },
  [Page.chats]: { name: "All Chats", route: routes.chats },
  [Page.dialer]: { name: "Dialer", route: routes.dialer },
  [Page.callHistory]: { name: "Call history", route: routes.calls },
  [Page.activeCall]: { name: "Active Call", route: routes.activeCall },
  [Page.callForwarding]: {
    name: "Call forwarding",
    route: routes.callForwarding,
  },
  [Page.callRecordings]: {
    name: "Call recordings",
    route: routes.callRecodings,
  },
  [Page.callProcedures]: {
    name: "Call procedures",
    route: routes.callProcedures,
  },
  [Page.callRecordingTable]: {
    name: "Call Recordings",
    route: routes.callRecordingTable,
  },
  [Page.callRecordingSettings]: {
    name: "Settings",
    route: routes.callRecordingSettings,
  },
  [Page.sms]: { name: "SMS & MMS", route: routes.sms },
  [Page.smsTemplates]: { name: "Templates", route: routes.smsTemplates },
  [Page.smsList]: { name: "SMS List", route: routes.smsList },
  [Page.autoResponder]: { name: "Auto Responder", route: routes.autoResponder },
  [Page.gallery]: { name: "Gallery", route: routes.gallery },
  [Page.voicemails]: { name: "Voicemails", route: routes.voicemails },
  [Page.greetings]: { name: "Greetings", route: routes.greetings },
  [Page.fax]: { name: "Fax", route: routes.fax },
  [Page.map]: { name: "Map", route: routes.map },
};

export const pageByMessageType: Record<MessageType, Page> = {
  [MessageType.call]: Page.callHistory,
  [MessageType.sms]: Page.sms,
  [MessageType.mms]: Page.sms,
  [MessageType.voicemail]: Page.voicemails,
  [MessageType.fax]: Page.fax,
  [MessageType.callRecording]: Page.callRecordings,
};

export const messageTypesByPage = (page: Page): MessageType[] => {
  switch (page) {
    case Page.chats:
      return [
        MessageType.call,
        MessageType.sms,
        MessageType.mms,
        MessageType.voicemail,
        MessageType.fax,
        MessageType.callRecording,
      ];
    case Page.callHistory:
      return [MessageType.call];
    case Page.sms:
      return [MessageType.sms, MessageType.mms];
    case Page.gallery:
      return [MessageType.mms];
    case Page.voicemails:
      return [MessageType.voicemail];
    case Page.fax:
      return [MessageType.fax];
    case Page.callRecordings:
      return [MessageType.callRecording];
    default:
      return [];
  }
};

export const featureNavigation: Record<
  Feature,
  { name: string; mode: AppMode; page: Page }
> = {
  [Feature.calls]: {
    name: "Calls",
    mode: AppMode.calls,
    page: Page.callHistory,
  },
  [Feature.sms]: { name: "SMS", mode: AppMode.sms, page: Page.sms },
  [Feature.mms]: { name: "MMS", mode: AppMode.sms, page: Page.sms },
  [Feature.voicemails]: {
    name: "Voicemails",
    mode: AppMode.voicemails,
    page: Page.voicemails,
  },
  [Feature.fax]: { name: "Fax", mode: AppMode.fax, page: Page.fax },
  [Feature.callForwarding]: {
    name: "Call Forwarding",
    mode: AppMode.calls,
    page: Page.callForwarding,
  },
  [Feature.callRecording]: {
    name: "Call Recording",
    mode: AppMode.calls,
    page: Page.callRecordings,
  },
  [Feature.callProcedures]: {
    name: "Call Procedures",
    mode: AppMode.calls,
    page: Page.callProcedures,
  },
};

export const messageTypeNavigation: Record<
  MessageType,
  { mode: AppMode; page: Page }
> = {
  [MessageType.call]: {
    mode: AppMode.calls,
    page: Page.callHistory,
  },
  [MessageType.sms]: { mode: AppMode.sms, page: Page.sms },
  [MessageType.mms]: { mode: AppMode.sms, page: Page.sms },
  [MessageType.voicemail]: {
    mode: AppMode.voicemails,
    page: Page.voicemails,
  },
  [MessageType.fax]: { mode: AppMode.fax, page: Page.fax },
  [MessageType.callRecording]: {
    mode: AppMode.calls,
    page: Page.callRecordings,
  },
};
