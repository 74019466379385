import { storageKeys } from "const/storage-keys";
import { ApiService, useApiService } from "./useApiService";
import { getStorageItem, setStorageItem } from "helpers/storage";
import { urls } from "const/urls";
import { Id, PhoneNumber } from "models/common";
import { MediaService, useMediaService } from "./useMediaService";
import { Direction, Message, MessageType } from "models/Message";
import { ContactService, useContactService } from "./useContactService";
import { MyNumberService, useMyNumberService } from "./useMyNumberService";
import { Feature } from "models/MyNumber";
import { generateGuid } from "helpers/guid";
import * as _ from "lodash-es";
import { AuthService, useAuthService } from "./useAuthService";
import { DraftService, useDraftService } from "./useDraftService";
import { Media, MediaType } from "models/Media";
import { showToast } from "helpers/toast";
import { useService, Service, UpdateDispatcher } from "./useService";

const ud: UpdateDispatcher = new Set();

export function useFaxService(): FaxService {
  const api = useApiService();
  const auth = useAuthService();
  const myNumberService = useMyNumberService();
  const contactService = useContactService();
  const mediaService = useMediaService();
  const authService = useAuthService();
  const draftService = useDraftService();
  return useService(ud, FaxService, {
    api,
    auth,
    myNumberService,
    contactService,
    mediaService,
    authService,
    draftService,
  });
}

export class FaxService extends Service {
  public static serviceName = "FaxService";

  private faxHistory: Record<Id, Message[]> =
    getStorageItem(storageKeys.fax.messages) || {}; // key is contactId; messages are sorted `at desc`

  // WARNING: don't forget to care about data index integrity!
  private messageById: Record<Id, Message> = _.fromPairs(
    _.flatten(Object.values(this.faxHistory))
      .filter((m) => m.entityId)
      .map((m) => [m.entityId, m])
  );
  private messagesByNumbers: Record<Id, Message[]> = // key is [myNumber, contactNumber].join('-')
    _.groupBy(
      _.flatten(Object.values(this.faxHistory)),
      (m: Message) => `${m.myNumber}-${m.contactNumber}`
    );
  private myFaxPhoneNumbers: PhoneNumber[] = [];

  private readonly api: ApiService;
  private readonly auth: AuthService;
  private readonly myNumberService: MyNumberService;
  private readonly contactService: ContactService;
  private readonly mediaService: MediaService;
  private readonly authService: AuthService;
  private readonly draftService: DraftService;

  constructor({
    api,
    auth,
    myNumberService,
    contactService,
    mediaService,
    authService,
    draftService,
  }: {
    api: ApiService;
    auth: AuthService;
    myNumberService: MyNumberService;
    contactService: ContactService;
    mediaService: MediaService;
    authService: AuthService;
    draftService: DraftService;
  }) {
    super({
      api,
      auth,
      myNumberService,
      contactService,
      mediaService,
      authService,
      draftService,
    });
    this.api = api;
    this.auth = auth;
    this.myNumberService = myNumberService;
    this.contactService = contactService;
    this.mediaService = mediaService;
    this.authService = authService;
    this.draftService = draftService;
  }

  public update() {
    setStorageItem(storageKeys.fax.messages, this.faxHistory);
    super.update();
  }

  public async init() {
    this.onDependentServiceUpdate({ serviceName: MyNumberService.name });
    this.authService.onLoginHandlers.push(() =>
      this.onDependentServiceUpdate({ serviceName: MyNumberService.name })
    );
    this.authService.onLogoutHandlers.push(() => {
      this.faxHistory = {};
      this.messageById = {};
      this.messagesByNumbers = {};
    });
  }

  public async onDependentServiceUpdate({
    serviceName,
  }: {
    serviceName: string;
  }) {
    switch (serviceName) {
      case MyNumberService.name: {
        if (this.auth.callRecording || this.auth.callForwarding) {
          return;
        }
        const myFaxPhoneNumbers = this.myNumberService.myNumbers.filter((n) =>
          n.features.includes(Feature.fax)
        );
        const newPhoneNumbers = myFaxPhoneNumbers
          .filter((n) => !this.myFaxPhoneNumbers.includes(n.number))
          .map((n) => n.number);
        this.myFaxPhoneNumbers = myFaxPhoneNumbers.map((n) => n.number);
        if (!newPhoneNumbers.length) {
          break;
        }
        await Promise.all(
          newPhoneNumbers.map((number) => this.loadMessagesByMyNumber(number))
        );
        this.myNumberService.myNumbers
          .filter((myNumber) => newPhoneNumbers.includes(myNumber.number))
          .forEach((myNumber) => {
            myNumber.fax!.ready = true;
          });
        this.myNumberService.update();
        this.update();
        break;
      }
    }
  }

  public getMessagesByContactId(contactId: Id): Message[] {
    return this.faxHistory[contactId] || [];
  }

  public getAllMessages(): Message[] {
    return _.sortBy(_.flatten(Object.values(this.faxHistory)), "at", "desc");
  }

  public getContactIdByMedia(mediaId: Id): Id | undefined {
    if (!mediaId) {
      return undefined;
    }
    return _.toPairs(this.faxHistory)
      .filter(([, messages]) => messages.some((m) => m.mediaId === mediaId))
      .map(([contactId]) => contactId)[0];
  }

  private async loadMessagesByMyNumber(myNumber: string): Promise<void> {
    interface Response {
      count: number;
      next?: string;
      results: Array<{
        date_sent: string; // "2019-08-24T14:15:22Z",
        fax_id: string;
        from_number: string;
        to_number: string;
        direction: string;
        bad_rows: number;
        page_count: number;
        ecm_requested: string;
        ecm_used: boolean;
        image_resolution: string;
        image_size: string;
        local_station_id: string;
        result_code: any; // ??
        result_text: string;
        remote_station_id: string;
        success: boolean;
        transfer_rate: string;
        v17_disabled: boolean;
        jitterbuffer: string;
        source: string;
        rtp_autoflush_during_bridge: string;
        t38_gateway_format: string;
        t38_peer: string;
        t38_trace_read: string;
        display_number: string;
        document: string;
        call_status: string; // "complete"
      }>;
    }
    const firstPageUrl = urls.faxMessages.replace(":myNumber", myNumber);
    const countKey = storageKeys.fax.count.replace(":myNumber", myNumber);
    let url = firstPageUrl;
    do {
      const response = await this.api.get<Response>(url);
      if (url === firstPageUrl) {
        // page 1
        if (response.count === getStorageItem<number>(countKey)) {
          break;
        }
        setStorageItem(countKey, response.count || 0);
      }
      const newFaxes: Message[] = response.results
        .filter((x) => !this.messageById[x.fax_id])
        .map((x) => {
          const at = new Date(x.date_sent).getTime();
          const media =
            this.mediaService.getMediaByUrl(x.document) ||
            this.mediaService.addNewMedia({
              id: x.fax_id,
              url: x.document,
              ...(/^\d+x\d+$/.test(x.image_resolution)
                ? {
                    width: Number(x.image_resolution.split("x")[0]),
                    height: Number(x.image_resolution.split("x")[1]),
                  }
                : {}),
              type: MediaType.Fax,
            });
          const isIncoming = x.to_number === myNumber;
          return {
            myNumber,
            contactNumber: isIncoming ? x.from_number : x.to_number,
            direction: isIncoming ? Direction.in : Direction.out,
            type: MessageType.fax,
            entityId: x.fax_id,
            at,
            sentAt: at,
            mediaId: media.id,
          } as Message;
        });
      for (const fax of newFaxes) {
        this.addNewFax(fax);
      }
      url = response.next || "";
    } while (url);
    this.update();
  }

  private async addNewFax(message: Message) {
    const { myNumber, contactNumber } = message;
    if (message.type && message.type !== MessageType.fax) {
      throw new Error(
        `Cannot add fax message with messageType ${message.type}`
      );
    }
    if (!myNumber || !contactNumber) {
      throw new Error(
        "myNumber and contactNumber should be provided for the new fax message"
      );
    }
    message.type = MessageType.fax;
    message.entityId = message.entityId || generateGuid();
    const contact =
      this.contactService.getContactByNumber(message.contactNumber) ||
      this.contactService.addNewContact(message.contactNumber, message);
    contact.hasFax = true;
    this.faxHistory[contact.id] = this.faxHistory[contact.id] || [];
    this.faxHistory[contact.id].unshift(message);
    this.messageById[message.entityId] = message;
    const numbersKey = [message.myNumber, message.contactNumber].join("-");
    this.messagesByNumbers[numbersKey] =
      this.messagesByNumbers[numbersKey] || [];
    this.messagesByNumbers[numbersKey].push(message);
    this.update();
  }

  get totalMessageCount(): number {
    return _.flatten(Object.values(this.faxHistory)).length;
  }

  public async sendDraftMessage(
    contactId: Id = this.contactService.current?.id || ""
  ): Promise<Message> {
    const message = this.draftService.getDraftMessage(contactId);
    if (!message) {
      throw new Error(`message draft not found`);
    }
    if (!message?.body?.trim() && !message.mediaId) {
      throw new Error(`message or attachment is required to send the message`);
    }
    message.at = new Date().getTime();
    this.addNewFax(message);
    this.draftService.deleteDraftMessage(contactId);
    await this.sendMessage(message);
    if (!contactId) {
      this.contactService.current = this.contactService.getContactByNumber(
        message.contactNumber
      );
    }
    return message;
  }

  public async sendMessage(message: Message): Promise<void> {
    if (!message.mediaId) {
      return;
    }
    if (!message.failed) {
      _.remove(this.authService.account.customData.failedMessages, message);
      this.authService.saveCustomData();
    }
    delete message.failed;
    message.sending = true;
    this.update();
    interface MessageSentResponse {
      fax_id: string;
      date_sent: string;
      detail?: string; // in case of fail
    }
    const { myNumber, contactNumber } = message;
    let file: File | undefined = undefined;
    const media: Media = this.mediaService.getMediaById(message.mediaId)!;
    file =
      media.file ||
      new File([media.fileContent!], media.fileName!, { type: media.mimeType });
    try {
      const response = await this.api.post<MessageSentResponse>(
        urls.sendFax.replace(":myNumber", myNumber),
        {},
        {
          form: {
            to_number: contactNumber,
            file_name: media.fileName || "fax",
            ...(file && { document: file }),
          },
        }
      );
      if (!response.fax_id) {
        console.error(response);
        throw new Error(response.detail);
      }
      message.entityId = response.fax_id;
      message.at = message.sentAt = new Date(response.date_sent).getTime();
    } catch (e: any) {
      message.failed =
        e.message && typeof e.message === "string" ? e.message : true;
    } finally {
      delete message.sending;
    }
    if (message.failed) {
      this.authService.account.customData.failedMessages.push(message);
      this.authService.saveCustomData();
    }
    this.update();
  }

  public async deleteMessage(message: Message): Promise<void> {
    const { entityId: faxId, myNumber, contactNumber } = message;
    if (!faxId) {
      console.error("Cannot remove fax without faxId");
      return;
    }
    const response = await this.api.delete(
      urls.deleteFax.replace(":myNumber", myNumber).replace(":faxId", faxId)
    );
    if (response !== true) {
      console.log(response);
      return;
    }
    const contact = this.contactService.getContactByNumber(
      message.contactNumber
    );
    if (!contact) {
      showToast({
        severity: "error",
        summary: "Cannot delete the message",
        detail:
          "Sorry, unknown error has occurred during deleting the message.",
      });
      return;
    }
    _.pull(this.faxHistory[contact.id], message);
    delete this.messageById[faxId];
    _.pull(
      this.messagesByNumbers[[myNumber, contactNumber].join("-")],
      message
    );
    this.update();
  }
}
