import { environment } from "environments/index";

const { baseUrl } = environment;

export const routes = {
  login: `${baseUrl}login`,
  logout: `${baseUrl}logout`,
  home: baseUrl,
  activity: `${baseUrl}activity`,
  config: `${baseUrl}config`,
  tools: `${baseUrl}tools`,
  chats: `${baseUrl}chats`,
  sms: `${baseUrl}sms`,
  smsTemplates: `${baseUrl}templates`,
  smsList: `${baseUrl}sms-list`,
  autoResponder: `${baseUrl}auto-responder`,
  gallery: `${baseUrl}gallery`,
  calls: `${baseUrl}calls/history`,
  dialer: `${baseUrl}calls/dialer`,
  activeCall: `${baseUrl}calls/active`,
  callForwarding: `${baseUrl}calls/call-forwarding`,
  callRecodings: `${baseUrl}calls/call-recordings`,
  callProcedures: `${baseUrl}calls/call-procedures`,
  callRecordingTable: `${baseUrl}calls/call-recordings/table`,
  callRecordingSettings: `${baseUrl}calls/call-recordings/settings`,
  voicemails: `${baseUrl}voice-mails`,
  greetings: `${baseUrl}greetings`,
  fax: `${baseUrl}fax`,
  map: `${baseUrl}map`,
};
