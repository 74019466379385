import { Id } from "./common";

export enum MediaType {
  None = "",
  CallRecording = "CALL_RECORDING",
  Greeting = "VOICEMAIL_GREETING",
  Fax = "FAX",
  Voicemail = "VOICEMAIL",
  MMS = "MMS",
  RcsLogo = "RCS_LOGO",
  ContactPhoto = "CONTACT_PHOTO",
}

// key is documentId
export interface Media {
  id: Id; // for documents, loaded by GET documents/:customerNumber, id is document id; for client-side created it can be anything unique
  url?: string;
  downloadingFileContent?: boolean;
  fileContent?: string;
  file?: File; // for uploading
  size?: number;
  mimeType?: string;
  fileName?: string;
  duration?: number; // for audio & video, in seconds
  width?: number;
  height?: number; // for image & video
  description?: string;
  type: MediaType;
}
