import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { useChatService } from "hooks/services/useChatService";
import { Dropdown } from "primereact/dropdown";
import "./Activity.sass";
import { PhoneNumber } from "models/common";
import { allMessageTypes, Direction, MessageType } from "models/Message";
import * as _ from "lodash-es";
import { useCallRecordingService } from "hooks/services/useCallRecordingService";
import { useCallService } from "hooks/services/useCallService";
import { useFaxService } from "hooks/services/useFaxService";
import { useMmsService } from "hooks/services/useMmsService";
import { useSmsService } from "hooks/services/useSmsService";
import { useVoicemailService } from "hooks/services/useVoicemailService";
import { TabPanel, TabView } from "primereact/tabview";
import { Unread } from "./Unread/Unread";

enum Tab {
  Unread = 0,
  Inbound = 1,
  Outbound = 2,
}

const tabNames: Record<Tab, string> = {
  [Tab.Unread]: "Unread",
  [Tab.Inbound]: "Inbound",
  [Tab.Outbound]: "Outbound",
};

export const Activity = () => {
  const myNumberService = useMyNumberService();
  const callService = useCallService();
  const smsService = useSmsService();
  const mmsService = useMmsService();
  const faxService = useFaxService();
  const voicemailService = useVoicemailService();
  const callRecordingService = useCallRecordingService();
  const chatService = useChatService();

  const [myPhoneNumber, setMyPhoneNumber] = useState<PhoneNumber | "">(
    myNumberService.isReady && myNumberService.myNumbers.length === 1
      ? myNumberService.myNumbers[0].number
      : ""
  );
  const [tabIndex, setTabIndex] = useState(0);

  const myNumbersOptions = useMemo(
    () => [
      { label: "All my numbers", value: "" },
      ...(myNumberService.isReady
        ? myNumberService.myNumbers.map((n) => ({
            label: n.name,
            value: n.number,
          }))
        : []),
    ],
    [myNumberService]
  );

  useEffect(() => {
    if (myNumbersOptions.length === 2) {
      setMyPhoneNumber(myNumbersOptions[1].value);
    }
  }, [myNumbersOptions]);

  const serviceByMessageType = (messageType: MessageType) =>
    ({
      [MessageType.call]: callService,
      [MessageType.sms]: smsService,
      [MessageType.mms]: mmsService,
      [MessageType.fax]: faxService,
      [MessageType.voicemail]: voicemailService,
      [MessageType.callRecording]: callRecordingService,
    }[messageType]);

  const rows = useMemo(() => {
    if (!chatService.updates) {
      // it never happens, just to use variable to trigger updates everytime when new unread message appears
      return [];
    }
    const now = new Date().getTime();
    return [
      ...[1, 7, 30].map((days) => ({
        title: days === 1 ? "last 24 hours" : `last ${days} days`,
        ..._.fromPairs(
          allMessageTypes.map((type) => {
            const messages = serviceByMessageType(type)
              .getAllMessages()
              .filter(
                (m) =>
                  (!myPhoneNumber || m.myNumber === myPhoneNumber) &&
                  m.at > now - days * 24 * 60 * 60 * 1000
              );
            return [
              type,
              {
                inbound: messages.filter((m) => m.direction === Direction.in)
                  .length,
                outbound: messages.filter((m) => m.direction === Direction.out)
                  .length,
              },
            ];
          })
        ),
      })),
    ];
  }, [chatService.updates, serviceByMessageType, myPhoneNumber]);

  const tableVisible = rows.some((row: any) =>
    allMessageTypes.some(
      (messageType) =>
        row[messageType][tabIndex === Tab.Inbound ? "inbound" : "outbound"]
    )
  );

  const inOutKey =
    tabIndex === Tab.Inbound
      ? "inbound"
      : tabIndex === Tab.Outbound
      ? "outbound"
      : "";

  return (
    <div className="activity-page">
      {myNumbersOptions.length > 2 && (
        <div className="my-number-selection">
          <label>Filter by number:</label>
          <Dropdown
            value={myPhoneNumber}
            options={myNumbersOptions}
            onChange={(e) => setMyPhoneNumber(e.value)}
          />
        </div>
      )}
      <TabView
        activeIndex={tabIndex}
        onTabChange={({ index }) => setTabIndex(index)}
      >
        {Object.values(tabNames).map((tabName, i) => (
          <TabPanel key={i} header={tabName} />
        ))}
      </TabView>

      {tabIndex === Tab.Unread && <Unread myPhoneNumber={myPhoneNumber} />}

      {(tabIndex === Tab.Inbound || tabIndex === Tab.Outbound) &&
        (!tableVisible ? (
          <>
            {tabIndex === Tab.Inbound && (
              <em>
                No inbound messages for last month
                {myPhoneNumber
                  ? ` to ${
                      myNumbersOptions.find((o) => o.value === myPhoneNumber)
                        ?.label || myPhoneNumber
                    }`
                  : ""}
                .
              </em>
            )}
            {tabIndex === Tab.Outbound && (
              <em>
                No outbound messages for last month
                {myPhoneNumber
                  ? ` from ${
                      myNumbersOptions.find((o) => o.value === myPhoneNumber)
                        ?.label || myPhoneNumber
                    }`
                  : ""}
                .
              </em>
            )}
          </>
        ) : (
          <DataTable value={rows} className="activity-table">
            <Column header="" body={(row) => row.title}></Column>
            <Column
              header="Total"
              body={(row) => (
                <strong>
                  {allMessageTypes.reduce((a, x) => a + row[x][inOutKey], 0)}
                </strong>
              )}
            ></Column>
            {allMessageTypes
              .filter((messageType) =>
                rows.some((row: any) => row[messageType]?.[inOutKey])
              )
              .map((messageType) => (
                <Column
                  key={messageType}
                  header={messageType}
                  body={(row) =>
                    row[messageType][inOutKey] > 0
                      ? row[messageType][inOutKey]
                      : "—"
                  }
                ></Column>
              ))}
          </DataTable>
        ))}
    </div>
  );
};
