import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { routes } from "./const/routes";
import { AuthGuard } from "./guards/AuthGuard";
import { BasicLayout } from "./layout/BasicLayout/BasicLayout";
import { Login } from "./pages/auth/Login";
import { Logout } from "./pages/auth/Logout";
import { AppLayout } from "./layout/AppLayout/AppLayout";
import { Page, pageInfo } from "./models/navigation";
import { Home } from "./pages/Home/Home";
import { Messages } from "./pages/chats/Messages/Messages";
import { Dialer } from "./pages/calls/Dialer/Dialer";
import { ActiveCall } from "./pages/calls/ActiveCall/ActiveCall";
import { SmsList } from "./pages/sms/SmsList/SmsList";
import { Greetings } from "./pages/voicemails/Greetings/Greetings";
import { AutoResponder } from "pages/sms/AutoResponder/AutoResponder";
import { SmsTemplates } from "pages/sms/Templates/Templates";
import { Gallery } from "pages/sms/Gallery/Gallery";
import { CallForwarding } from "pages/calls/CallForwarding/CallForwarding";
import { CallProcedures } from "pages/calls/CallProcedures/CallProcedures";
import { Map } from "pages/map/Map";
import { useNavigationService } from "hooks/services/useNavigationService";
import { Loader } from "components/Loader/Loader";
import { useAuthService } from "hooks/services/useAuthService";
import { useMyNumberService } from "hooks/services/useMyNumberService";
import { CallRecordingTable } from "pages/callRecordings/CalRecordingTable/CallRecordingTable";
import { CallRecordingSettings } from "pages/callRecordings/Settings/CallRecordingSettings";
import { Activity } from "pages/Home/Activity/Activity";
import { Config } from "pages/Home/Config/Config";
import { Tools } from "pages/Home/Tools/Tools";

const pageComponents: Record<Page, React.FC> = {
  [Page.home]: () => <Home />,
  [Page.activity]: () => <Activity />,
  [Page.config]: () => <Config />,
  [Page.tools]: () => <Tools />,
  [Page.chats]: () => <Messages />,
  [Page.callHistory]: () => <Messages />,
  [Page.dialer]: () => <Dialer />,
  [Page.activeCall]: () => <ActiveCall />,
  [Page.callForwarding]: () => <CallForwarding />,
  [Page.callRecordings]: () => <Messages />,
  [Page.callProcedures]: () => <CallProcedures />,
  [Page.callRecordingTable]: () => <CallRecordingTable />,
  [Page.callRecordingSettings]: () => <CallRecordingSettings />,
  [Page.sms]: () => <Messages />,
  [Page.smsTemplates]: () => <SmsTemplates />,
  [Page.smsList]: () => <SmsList />,
  [Page.autoResponder]: () => <AutoResponder />,
  [Page.gallery]: () => <Gallery />,
  [Page.voicemails]: () => <Messages />,
  [Page.greetings]: () => <Greetings />,
  [Page.fax]: () => <Messages />,
  [Page.map]: () => <Map />,
};

export const AppRoutes = () => {
  const navigation = useNavigationService();
  const auth = useAuthService();
  useMyNumberService(); // initialize myNumberService at early stage

  return (
    <>
      <BasicLayout>
        <Routes>
          <Route path={routes.login} element={<Login />}></Route>
          <Route path={routes.logout} element={<Logout />}></Route>
          {navigation.modes.map((mode) =>
            (navigation.pagesByMode[mode] || []).map((page) => {
              const PageComponent = pageComponents[page];
              return (
                <Route
                  path={pageInfo[page].route}
                  element={
                    <AuthGuard>
                      <AppLayout mode={mode} page={page}>
                        <PageComponent />
                      </AppLayout>
                    </AuthGuard>
                  }
                ></Route>
              );
            })
          )}
          <Route
            path="*"
            element={
              <div>
                {!navigation.isReady ? (
                  <Loader />
                ) : !auth.authenticated ? (
                  <Navigate to={routes.login} />
                ) : navigation.isReady && navigation.modes.length === 0 ? (
                  <em>No available features found for the user</em>
                ) : (
                  <>404 Not found</>
                )}
              </div>
            }
          ></Route>
        </Routes>
      </BasicLayout>
    </>
  );
};
