import React, { useMemo, useState } from "react";
import { Loader } from "components/Loader/Loader";
import { useApiService } from "hooks/services/useApiService";
import { urls } from "const/urls";
import { showToast } from "helpers/toast";
import { formatPhoneNumber, toDigitalNumber } from "helpers/phone";
import "./Info.sass";
import { Checkbox } from "primereact/checkbox";

const checkTypes: Record<
  string,
  { name: string; url: string; fields: Record<string, string> }
> = {
  dnc: {
    name: "Do Not Call Registry",
    url: urls.checkDoNotCallRegistry,
    fields: {
      dnc: "Do Not Call Registry",
    },
  },
  info: {
    name: "Phone Number Info",
    url: urls.getPhoneNumberInfo,
    fields: {
      lata: "LATA",
      ocn: "OCN",
      rate_center: "Rate Center",
      state: "State",
      portable: "Portable",
    },
  },
  cnam: {
    name: "CNAM Info",
    url: urls.getCnamInfo,
    fields: {
      name: "CNAM",
    },
  },
  lrn: {
    name: "LRN Info",
    url: urls.getLrnInfo,
    fields: {
      as_of_date: "As of Date",
      porting_status: "Porting Status",
      ported_on_date: "Ported On Date",
      lrn: "LRN",
      lata: "LATA",
      primary_spid: "Primary SPID",
      primary_spid_category: "Primary SPID Category",
      primary_lerg_carrier_name: "Primary LERG Carrier Name",
      primary_spid_carrier_name: "Primary SPID Carrier Name",
      city: "City",
      state: "State",
      alt_spid: "Alt SPID",
      alt_spid_category: "Alt SPID Category",
      alt_lerg_carrier_name: "Alt LERG Carrier Name",
      alt_spid_carrier_name: "Alt SPID Carrier Name",
    },
  },
};

export const Info = () => {
  const api = useApiService();

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [checkedPhoneNumber, setCheckedPhoneNumber] = useState("");
  const [results, setResults] = useState<
    Record<string, Record<string, string>>
  >({}); // { dnc: { dnc: boolean }, info: { lata: string, ocn: string, rate_center: string, state: string, portable: boolean }, .... }

  const valid = useMemo(
    () => phoneNumber.length === 10 && selectedTests.length > 0,
    [phoneNumber.length, selectedTests.length]
  );

  const submit = async () => {
    setLoading(true);
    setCheckedPhoneNumber("");
    const tests = [...selectedTests];
    try {
      const responses = await Promise.all(
        tests.map((t) =>
          api.get(checkTypes[t].url.replace(":phoneNumber", phoneNumber))
        )
      );
      setCheckedPhoneNumber(phoneNumber);
      setResults(
        Object.fromEntries(
          tests.map((t, i) => [
            t,
            (t === "dnc" ? { dnc: responses[i] } : responses[i]) as Record<
              string,
              string
            >,
          ])
        )
      );
    } catch {
      showToast({
        severity: "error",
        summary: "An error occurred",
        detail: `An occurred during retrieving ${tests.map(
          (t) => checkTypes[t].name
        )} . Please try again later or contact us`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tools-page-info-tab">
      <div className="form">
        <form>
          <div className="form-group">
            <label>Phone Number</label>
            <div>
              <input
                type="text"
                className="textbox"
                value={formatPhoneNumber(phoneNumber)}
                onChange={(e) =>
                  setPhoneNumber(toDigitalNumber(e.target.value))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div>
              {Object.keys(checkTypes).map((key) => (
                <label>
                  <Checkbox
                    checked={selectedTests.includes(key)}
                    onChange={({ checked }) =>
                      setSelectedTests((prev) =>
                        checked ? [...prev, key] : prev.filter((k) => k !== key)
                      )
                    }
                  />{" "}
                  {checkTypes[key]?.name}
                </label>
              ))}
            </div>
          </div>
          <button
            className={`button submit-button ${
              (!valid || loading) && "disabled"
            }`}
            onClick={submit}
            disabled={!valid || loading}
          >
            Get Info
          </button>
        </form>
      </div>
      {loading ? (
        <Loader />
      ) : checkedPhoneNumber ? (
        <div>
          <h3>Phone Number: {formatPhoneNumber(checkedPhoneNumber)}</h3>
          {Object.entries(results).map(([key, result]) => (
            <div key={key}>
              <h4>{checkTypes[key].name}</h4>
              {Object.entries(checkTypes[key].fields).map(
                ([field, fieldName]: [string, string]) => (
                  <p>
                    {fieldName}:{" "}
                    <strong>
                      {typeof result[field] === "boolean"
                        ? result[field]
                          ? "Yes"
                          : "No"
                        : result[field]}
                    </strong>
                  </p>
                )
              )}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
